<template>
  <div class="container">
    <div @click="goback" style="font-size:14px;margin-bottom:10px;cursor: pointer;width:50px">
      <i class="el-icon-back"></i>
      返回
    </div>
    <div class="search" style="float:right;margin-bottom:10px;">
      <div>
        <el-button type="primary" size="small" @click="addFac">新建</el-button>
      </div>
    </div>
    <!-- 列表 -->
    <el-table border v-bind:data="list">
      <el-table-column label="序号" type="index" width="50"></el-table-column>
      <el-table-column label="工厂名称">
        <template v-slot="scope">
          <span>{{ scope.row.describe }}</span>
        </template>
      </el-table-column>
      <el-table-column label="工厂sn">
        <template v-slot="scope">
          <span>{{ scope.row.describe }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="450px">
        <template v-slot="scope">
          <el-button type="danger" size="small" @click="deleteFac(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script type="text/ecmascript-6">
export default {
  name: '',
  mixins: [],
  components: {},
  props: {},
  data () {
    return {
      search: {
        cnsuo: '',
        cocn: '',
      },
      list: [
        {describe: '一个毛栗子'}
      ],
    }
  },
  computed: {},
  watch: {},
  methods: {
    addFac () {
    },
    deleteFac(item) {
      this.$confirm('是否删除该型号?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 删除请求
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消删除'
        });
      })
    },
    goback () {
      this.$router.push('/iot/promodel')
    }
  },
  filters: {},
  mounted () { },
  created () { }
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
</style>
